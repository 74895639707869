import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import router from './router';
import "@arco-design/web-react/dist/css/arco.css";
import PageLoading from "@/components/PageLoading";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <Routes>
      {
        router.map((item) => (
          <Route key={item.path} path={item.path} element={<Suspense fallback={<PageLoading />}><item.component /></Suspense>
          }>
            {
              item.children?.map(i => {
                if (i.index) {
                  return (<>
                    <Route index element={<i.component></i.component>}></Route>
                    <Route path={i.path} element={<i.component></i.component>}></Route>
                  </>)
                } else {
                  return <Route path={i.path} element={<i.component></i.component>}></Route>
                }
              })
            }
          </Route>
        ))
      }

    </Routes>
  </BrowserRouter>
);
