import { lazy } from "react";

interface IRouter {
    name?: string;
    index?: boolean;
    path: string;
    component: any;
    children?: Array<IRouter>
}

const router: Array<IRouter> = [
    {
        path: "/",
        component: lazy(() => import("@/App")),
        children: [{
            path: '/dashboard',
            index: true,
            component: lazy(() => import("@/pages/DashBoard"))
        }]
    },
    {
        path: "login",
        component: lazy(() => import("@/pages/Login"))
    },
    {
        path: "social-callback",
        component: lazy(() => import("@/pages/SocialCallback"))
    },
    {
        path: '*',
        component: lazy(() => import("@/pages/NotFound"))
    }
]

export default router;